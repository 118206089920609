import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CanActivate }      from '@angular/router';

import { AuthGuard }        from './guard/auth.guard';

import { BuildingsComponent }   from './buildings/buildings.component';   // 物件一覧
import { CustomersComponent }   from './customers/customers.component';   // 得意先一覧
import { AgreementsComponent }  from './agreements/agreements.component'; // 契約一覧
import { ContractDetailComponent }  from './contract-detail/contract-detail.component'; // 契約詳細
import { InvoicesComponent }    from './invoices/invoices.component';     // 請求一覧
import { StaffsComponent }      from './staffs/staffs.component';         // 清掃員一覧
import { BuildingSettingComponent }    from './building-setting/building-setting.component'; // 代勤不可
import { BuildingSettingDetailComponent }    from './building-setting-detail/building-setting-detail.component'; // 代勤不可詳細
import { ImporterComponent }    from './importer/importer.component';     // インポート
import { BuildingDetailComponent } from './building-detail/building-detail.component';
import { CustomerDetailComponent } from './customer-detail/customer-detail.component';
import { AgreementDetailComponent } from './agreement-detail/agreement-detail.component';
import { LoginComponent } from './login/login.component';
import { UserMasterComponent } from './user-master/user-master.component';
import { AreaMasterComponent } from './area-master/area-master.component';
import { OperationComponent }  from './operation/operation.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { NationalHolidayComponent } from './national-holiday/national-holiday.component'; // 祝日設定画面
import { PaidHolidayComponent } from './paid-holiday/paid-holiday.component'; // 有休確認
import { RecruitComponent } from './recruit/recruit.component'; // 欠員募集
import { VacancyComponent } from './vacancy/vacancy.component'; // 欠員確認
import { LogComponent } from './log/log.component'; // ログ画面

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full'},
  { path: 'login', component: LoginComponent },
  { path: 'buildings', component: BuildingsComponent, canActivate: [AuthGuard] },
  { path: 'customers', component: CustomersComponent, canActivate: [AuthGuard] },
  { path: 'agreements', component: AgreementsComponent, canActivate: [AuthGuard] },
  { path: 'contractDetail', component: ContractDetailComponent, canActivate: [AuthGuard] },
  { path: 'invoices', component: InvoicesComponent, canActivate: [AuthGuard] },
  { path: 'staffs', component: StaffsComponent, canActivate: [AuthGuard] },
  // { path: 'staffs/:staff/:cdSite/:sequenceNumber', component: StaffDetailComponent, canActivate: [AuthGuard] },
  { path: 'buildingSetting', component: BuildingSettingComponent, canActivate: [AuthGuard] },
  { path: 'buildingSetting/:cdSite/:cdClient', component: BuildingSettingDetailComponent, canActivate: [AuthGuard] },
  { path: 'buildings/:id', component: BuildingDetailComponent, canActivate: [AuthGuard] },
  { path: 'customers/:id', component: CustomerDetailComponent, canActivate: [AuthGuard] },
  { path: 'agreements/detail', component: AgreementDetailComponent, canActivate: [AuthGuard] },
  { path: 'operations', component: OperationComponent, canActivate: [AuthGuard] },
  { path: 'operations/:hasHoliday', component: OperationComponent, canActivate: [AuthGuard] },
  { path: 'importer', component: ImporterComponent, canActivate: [AuthGuard] },
  { path: 'userMaster', component: UserMasterComponent, canActivate: [AuthGuard]},
  { path: 'areaMaster', component: AreaMasterComponent, canActivate: [AuthGuard]},
  { path: 'confirmation', component: ConfirmationComponent, canActivate: [AuthGuard]},
  { path: 'nationalHoliday', component: NationalHolidayComponent, canActivate: [AuthGuard]},
  { path: 'paidHoliday', component: PaidHolidayComponent, canActivate: [AuthGuard]},
  { path: 'recruit', component: RecruitComponent, canActivate: [AuthGuard]},
  { path: 'vacancy', component: VacancyComponent, canActivate: [AuthGuard]},
  { path: 'log', component: LogComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {}