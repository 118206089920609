import { Component, OnInit, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from '../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-master',
  templateUrl: './user-master.component.html',
  styleUrls: ['./user-master.component.less']
})
export class UserMasterComponent implements OnInit {

  users: any[] = [];
  newUser: any = {
    id: null,
    userId: "",
    password: "",
    outsource: false,
    deleteHoliday: false,
    cannotInstead: false,
    userManagement: false,
    authority: "2"
  };
  loading = true

  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  constructor(private http: HttpClient, private router: Router, private ngZone: NgZone) { }

  ngOnInit() {
    this.getUserData();
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
   * ユーザー一覧データ取得
   */
  getUserData() {
    this.loading = true
    this.http.get(API_BASE_URL + "/api/v1/users", {
      headers: this.headers
    }).subscribe(res=> {
      console.info(res);
      this.users = res as any[];
      for(let i = 0; i < this.users.length; i++) {
        this.users[i].authority = this.users[i].authority + "";
      }
      this.loading = false
    }, error => {
      this.loading = false
      alert("セッションが切れています");
      this.router.navigate(['/']);
    })
  }

  /**
   * 保存ボタン
   * @param user ユーザー情報
   */
  save(user: any) {
    this.http.post(API_BASE_URL + "/api/v1/users/" + user["id"], user, {
      headers: this.headers
    }).subscribe(res => {
      this.getUserData();
      alert("ユーザ情報を更新しました");
    }, error => {
      alert("ユーザ情報の更新に失敗しました");
    });
  }

  compareFn(d1: number, d2: number) {
    return d1 == d2;
  }

  /**
   * 削除ボタン
   * @param user ユーザー情報
   */
  delete(user: any) {
    if (window.confirm(`ユーザーID：${user.userId} を削除してよろしいですか？`)) {
      this.http.post(API_BASE_URL + "/api/v1/users/delete", user, {
        headers: this.headers
      }).subscribe(res => {
        this.getUserData();
        alert("ユーザを削除しました");
      }, error => {
        alert("ユーザの削除に失敗しました");
      });
    }
  }

  /**
   * ユーザを新規登録します．
   * @param user 
   */
  add(user: any) {
    this.http.post(API_BASE_URL + "/api/v1/users", user, {
      headers: this.headers
    }).subscribe(res => {
      alert("ユーザを新規追加しました");
      this.ngOnInit();
    }, error => {
      alert("ユーザの新規追加に失敗しました");
    });
  }

  /**
   * ユーザー管理権限を取得
   * @returns ユーザー管理権限
   */
  getUserManagementAuth() {
    return localStorage.getItem("userManagement");
  }
}
