import { Component, OnInit, Inject, ViewChild, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatTableDataSource, MatPaginator, MatDialogRef, MatDialog } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from '../../../environments/environment';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import _ from 'lodash'
import { SmsDialogComponent } from '../sms-dialog/sms.component';

export interface DialogData {
  any
}
export interface Staff {
  nameStaff: string;
  numberPhoneStaff: string;
  numberMobilePhoneStaff: string;
  staffLatitude: string;
}

@Component({
  selector: 'app-operation-dialog',
  templateUrl: './operation-dialog.component.html',
  styleUrls: ['./operation-dialog.component.less']
})
export class OperationDialogComponent implements OnInit {

  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  // 画面上半分に表示するデータ
  operationDialogData: any;
  // 代勤登録する日付
  day: string = moment(this.data['day']).format('YYYY-MM-DD');
  // 画面表示用日付
  dayForDisp: string = moment(this.data['day']).format('YYYY/MM/DD');
  // 清掃員一覧テーブルのデータソース
  dataSource = new MatTableDataSource();
  // 清掃員一覧の全レコードの件数
  dataSourceLength = 0;
  // ページネーター
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // 絞込み清掃員名
  filterName = "";
  // 絞込み清掃員名カナ
  filterNameKana = "";
  // 絞込み物件名
  filterSiteName = "";
  // 絞込みエリアドロップダウン
  areas = [];
  // 絞込みエリア
  areaIds = "";
  // カラム名
  columnDefs = [
    'name',
    'numberPhone',
    'numberMobilePhone',
    'canInstead'
  ];
  // チェックボックス「実績あり」
  hasWorked = false;
  // チェックボックス「代勤経験者」
  hasExperience = false;
  // チェックボックス「空き清掃員」
  freeStaff = false;
  // チェックボックス「住所の近い順」
  position = false;
  // チェックボックス「代勤不可を除く」
  canInstead = false;
  // 物件の緯度経度が取れない場合のメッセージ
  noPositionMsg = '';
  // GoogleMapの位置が取れない場合のメッセージ
  noMapMsg = '';
  // 代勤者
  workInsteads = [];
  // 代勤ステータスドロップダウン
  statusChoices = [
    {key: "unknown", value: "未登録"},
    {key: "alreadyRegistered", value: "登録済み"},
    {key: "ok", value: "●：代勤OK"},
    {key: "ng", value: "×：代勤行けない"},
    {key: "noContact", value: "×：連絡とれず"},
    {key: "request", value: "復帰確認"},
    {key: "cantReturn", value: "復帰不可・連絡取れず"},
    {key: "holiday", value: "休み取り消し"}
  ];
  // 代勤ステータス
  status = this.statusChoices[0];
  // メモ
  note = "";
  // 物件名
  siteName = "";
  // 物件住所
  siteAddress = "";
  // GoogleMap URL
  routeMap = "";
  // 休み種別
  holidayStatus = null;

  // 代勤の最終更新者
  operationCreatedUser = "";
  // 代勤の最終更新日
  operationCreatedDate = "";
  // 休みの最終更新者
  statusInsertedUser = "";
  // 休みの最終更新日
  statusInsertedDate = "";

  // 見せかけの欠員の場合、契約詳細情報の時間を表示（欠員物件以外）
  contractTime = "";

  // 通常清掃員の予定勤務開始時間
  startTime = "";
  // 通常清掃員の予定勤務終了時間
  endTime = "";
  // 時間ドロップダウン
  hours: number[] = [];
  // 分ドロップダウン
  minutes: string[] = [];
  // 通勤時間
  commuteMinutes: number[] = [];

  // 「変更を保存」ボタン　活性:true、非活性:false
  saveDisabled:boolean = false;
  // 契約時間との差
  timeDiff: number;
  // 時間差分取得不可のメッセージ
  timeDiffMsg = "";
  // 既に登録している実働時間
  existingWorkingHours = 0;
  // 複数日登録画面
  multipleDay = false;
  
  // 画面表示　初期値：連日
  displaySpecifyDate = 'consecutive';
  // 複数日選択
  daysSelected: any[] = [];  
  daysArray: any[] = [];
  // 選択した日（表示用）
  days:string = '';
  // 曜日ドロップダウン
  weekly = [
    {key: 1, value: '月曜日'},
    {key: 2, value: '火曜日'},
    {key: 3, value: '水曜日'},
    {key: 4, value: '木曜日'},
    {key: 5, value: '金曜日'},
    {key: 6, value: '土曜日'},
    {key: 0, value: '日曜日'}
  ];
  // 選択した曜日
  week = [];
  // 開始日
  start: Date = new Date(this.data['day']);
  // 終了日
  end: Date = new Date(this.data['day']);
  // tbl_operation_noteに保存するデータ
  operationNote: any[] = [];
  // 更新した代勤ID
  updateOperationId = [];
  // 削除されたデータ
  remove = [];

  /** ローディング */
  loadingCompleteData:boolean = false;
  loadingCompleteSize:boolean = false;
  updateCompleteData:boolean = true;

  constructor(private router: Router, private http: HttpClient, @Inject(MAT_DIALOG_DATA) private data: DialogData, private dialogRef: MatDialogRef<OperationDialogComponent>,
    private datePipe: DatePipe, private dialog: MatDialog, private ngZone: NgZone) { }

  ngOnInit() {
    // console.info("data ↓");
    // console.info(this.data);
    this.holidayStatus = this.data['data']['statusArray'][this.data['target'] - 1];
    // console.info(this.holidayStatus);

    // 契約詳細情報の時間帯
    if (this.data['data']['tentative'][this.data['target'] - 1] == '1' && this.data['data']['staffDailyCleaning'] != '-1' && this.data['data']['show'] != 0) {
      this.contractTime = this.data['data']['cdWorkingTime'][this.data['target'] - 1] + ' (' + this.data['data']['cdHours'][this.data['target'] - 1] + '時間)'
    }

    let target = this.data['target'];
    let data = this.data['data'];
    
    // 休みの更新情報生成
    let statusInfo = this.getStatusCreatedInfo(data, target);
    this.statusInsertedUser = statusInfo.createdUser;
    this.statusInsertedDate = statusInfo.createdDate;

    //開始時間、終了時間の選択
    this.hours = this.generateNums(23, true);
    this.minutes = [ '00', '15', '30', '45' ];

    // 通常清掃員の勤務予定時間から半角スペースを削除
    let time = this.data['data']['workingTime'][this.data['target'] - 1].replace(/\s/g, '');
    if (this.contractTime != '' && this.data['data']['show'] != 0) {
      time = this.data['data']['cdWorkingTime'][this.data['target'] - 1].replace(/\s/g, '');
    }
    // 通常清掃員の予定勤務時間をセット
    if (time && time != '-' && time.match(/-/)) {
      let timeSplit = time.split('-');
      this.startTime = timeSplit[0];
      this.endTime = timeSplit[1];
      if (this.endTime.indexOf('(') > 0) {
        this.endTime = this.endTime.substring(0, this.endTime.indexOf('('))
      }
    } else {
      this.startTime = '0:00';
      this.endTime = '0:00';
    }

    // 通勤時間「分」の選択
    this.commuteMinutes = this.generateNums(59, true);

    this.getData();
    this.getAreas();
    this.applyFilter();
    this.getTimeDifference();
    this.paginator.page.subscribe(() => {
      this.getPicklist(this.paginator.pageIndex, this.paginator.pageSize);
    });
    
    let workInsteads = [];
    workInsteads = data.days[this.day];

    // 既に代勤登録されているデータがあれば取得し、表示する
    if (workInsteads.length > 0) {
      this.getWorkInsteadDataForSite((workInsteadData) => {
        for(let i = 0; i < workInsteads.length; i++) {
          for(let j = 0; j < workInsteadData.length; j++) {

            if (workInsteadData[j].id == workInsteads[i].operationId) {
              this.convertToWorkInsteadData(workInsteadData[j]);
            }
            if (workInsteadData[j].status == '●：代勤OK' &&workInsteadData[j].workingHours) {
              this.existingWorkingHours += Number(workInsteadData[j].workingHours);
            }
          }
        }
      });
    }
    
    // メモ
    if (this.data['data']['operationNote'].length && this.data['data']['operationNote'][this.data['target'] - 1]) {
      this.note = this.data['data']['operationNote'][this.data['target'] - 1];
    }
    if (this.holidayStatus && this.note == '') {
      // 休み登録より
      this.note = this.holidayStatus.note;
    } else if (workInsteads.length > 0 && this.note == '') {
      // 代勤務登録より
      this.note = workInsteads[0].note;
    } else if (this.note == undefined) {
      this.note = '';
    }
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
   * 連番生成
   * @param n 最大値
   * @param isStartZero 0からスタートする
   * @returns　連番配列 
   */
  generateNums(n: number, isStartZero): number[] {
    if (isStartZero) {
      n++;
      return Array.from({length: n}, (x, i) => i);
    } else {
      return Array.from({length: n}, (x, i) => i + 1);
    }
  }

  /**
   * 時間の設定
   * @param workInstead 設定値
   */
  setTime(workInstead){
    if(!workInstead.startTime) workInstead.startTime = '0:00';
    if(!workInstead.endTime) workInstead.endTime = '0:00';
    // 通勤時間は1分単位なので「0:0」とする
    if(!workInstead.commuteTime) workInstead.commuteTime = '0:0';
    
    let startTimeArr = workInstead.startTime.split(':');
    if(startTimeArr && startTimeArr.length){
      if(startTimeArr.length > 0){
        workInstead.startHour = Number(startTimeArr[0]); 
      }
      if(startTimeArr.length > 1){
        workInstead.startMinutes = startTimeArr[1]; 
      }
    }

    let endTimeArr = workInstead.endTime.split(':');
    if(endTimeArr && endTimeArr.length){
      if(endTimeArr.length > 0){
        workInstead.endHour = Number(endTimeArr[0]); 
      }
      if(endTimeArr.length > 1){
        workInstead.endMinutes = endTimeArr[1]; 
      }
    }

    let cTimeArr = workInstead.commuteTime.split(':');
    if(cTimeArr && cTimeArr.length){
      if(cTimeArr.length > 0){
        workInstead.commuteHour = Number(cTimeArr[0]); 
      }
      if(cTimeArr.length > 1){
        workInstead.commuteMinutes = Number(cTimeArr[1]);
      }
    }
  }

  /**
   * 時間変更イベント_開始時間
   * @param workInstead 代勤レコード
   */
  selectionChangeStartTime(workInstead) {
    this.saveDisabled = false;
    workInstead.startTime = workInstead.startHour + ':' + workInstead.startMinutes;

    // 開始時間、終了時間が逆になってる場合は、保存ボタンを非活性
    this.setSaveDisabled();

    // 交通費、MAPのURL取得
    workInstead = this.getTransAndMap(workInstead, false);
    // 実働時間設定
    this.setWorkingHours(workInstead);
  }

  /**
   * 時間変更イベント_終了時間
   * @param workInstead 代勤レコード
   */
  selectionChangeEndTime(workInstead) {
    this.saveDisabled = false;
    workInstead.endTime = workInstead.endHour + ':' + workInstead.endMinutes;

    // 開始時間、終了時間が逆になってる場合は、保存ボタンを非活性
    this.setSaveDisabled();

    // 実働時間設定
    this.setWorkingHours(workInstead);

    // 交通費、MAPのURL取得
    workInstead = this.getTransAndMap(workInstead, false);
  }

  /**
   * 時間変更イベント_通勤時間
   * @param workInstead 代勤レコード
   */
  selectionChangeCommutTime(workInstead) {
    workInstead.commuteTime = workInstead.commuteHour + ':' + workInstead.commuteMinutes;
  }

  /**
   * 金額keydownイベント
   * @param event イベント情報
   * @returns 
   */
   onkeydownMoney(event: any) { // without type info
    //制御のキーは有効( Left / Up / Right / Down Arrow, Backspace, Delete keys, Tab, Home, End)
    let keyCode = event.keyCode;
    if(keyCode == 37 || keyCode == 38 || keyCode == 39 || keyCode == 40 || keyCode == 8 || keyCode == 46 || keyCode == 9 || keyCode == 36 || keyCode == 35) { 
    return true;
    }

    //数字キーは有効
    let key = event.key;
    let result = key.match(/[^0-9]/);
    if (result) {
      return false;
    } 
    return true;
  }
  
  /**
   * 当日に当物件で代勤登録されているデータを取得
   */
  getWorkInsteadDataForSite(cb) {

    // 代勤登録されているデータを取得
    this.http.get(API_BASE_URL + '/api/v1/operations/workInsteadData?&staffDailyCleaning=' + this.data['data']['staffDailyCleaning']
      + '&cdSite=' + this.data['data']['cdSite']
      + '&sequenceNumber=' + this.data['data']['sequenceNumber']
      + '&day=' + this.day,
      {headers: this.headers})
      .subscribe((res) => {
        if(cb){
          cb(res);
        }
      },
      error => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
      }
    );
  }
  
  /**
   * 代勤が既に登録されている場合、代勤情報を表示する
   */
  async convertToWorkInsteadData(d: any) {
    console.info(d);

    // 出発地点をセットするため、代勤者のその日の物件データを取得
    let workInsteadDataByDay = await this.getWorkInsteadData(d['workInsteadStaffDailyCleaning']);
      
    // GoogleMap用の出発地をセット
    let today = this.setStartEndPoint(workInsteadDataByDay, d['startTime'], d['endTime'], d['workInsteadAddress'], d['operationId']);

    // 出発地点、目的地がともにあれば、GoogleMapを表示
    let routeMapValue = '';
    if (today.startPoint != '' && today.startPoint != null && this.operationDialogData && this.operationDialogData.addressSite != '') {
      routeMapValue = 'https://www.google.com/maps/dir/?api=1&origin=' + this.replaceSpace(today.startPoint) + '&destination=' + this.replaceSpace(this.siteAddress) + '&travelmode=transit&hl=ja&gl=jp'
    }

    // 代勤データをセット
    this.setWorkInstead(
      d['id'],
      d['workInsteadStaffDailyCleaning'],
      d['workInstead'],
      d['status'],
      d['startTime'],
      d['endTime'],
      d['numberPhone'],
      d['numberMobilePhone'],
      d['basicWage'],
      d['transportationExpenses'],
      d['workInsteadAddress'],
      routeMapValue,
      d['commuteTime'],
      d['workingHours'],
      d['workInsteadKubunWage'],
      d['takeOver'],
      d['noTransportCost'],
      '',
      workInsteadDataByDay,
      today
    );
  }

  /**
   * エリアドロップダウン生成
   */
  getAreas() {
    this.http.get(
      API_BASE_URL + "/api/v1/area", {headers: this.headers}
    ).subscribe(res => {
      this.areas = res as any[];
    }, error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * 現場に関係する清掃員情報を取得
   */
  getData() {
    this.http.get(API_BASE_URL + '/api/v1/sites/operationDialog?cdSite=' + this.data['data']['cdSite']
      + '&cdContract=' + this.data['data']['cdContract']
      + '&cdStaff=' + this.data['data']['staffDailyCleaning']
      + '&day=' + this.day
      + '&sequenceNumber=' + this.data['data']['sequenceNumber'],
      {headers: this.headers})
      .subscribe((res) => {
        if (res == null || (res as []).length == 0) {
          this.operationDialogData = null
          this.siteName = this.data['data']['nameSite'];
          this.siteAddress = '';
          return
        }
        this.operationDialogData = res;
        this.siteName = res[0].nameSite;
        this.siteAddress = res[0].addressSite;
        if (res[0].createdDate) {
          this.operationCreatedDate = res[0].createdDate;
          this.operationCreatedUser = res[0].createdUser;
        }
      },
      error => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
      }
    );
  }
  
  /**
   * 代勤候補者のリストを取得
   * @param index ページ数
   * @param size 1ページに表示する件数
   */
  getPicklist(index: number, size: number) {
    this.loadingCompleteData = false;

    if(size == undefined)
      size = 50;

    this.http.get(
      API_BASE_URL + '/api/v1/operations/picklist?s=0&e=8000&index=' + index
        + "&size=" + size
        + "&filterName=" + encodeURIComponent(this.filterName)
        + "&filterNameKana=" + encodeURIComponent(this.filterNameKana)
        + "&siteName=" + encodeURIComponent(this.filterSiteName)
        + "&cdSite=" + this.data['data']['cdSite']
        + "&areasStr=" + this.areaIds
        + "&hasWorked=" + this.hasWorked
        + "&hasExperience=" + this.hasExperience
        + "&freeStaff=" + this.freeStaff
        + "&position=" + this.position
        + "&canInstead=" + this.canInstead
        + "&day=" + this.day,
      {headers: this.headers}
    )
    .subscribe(res => {
      this.dataSource = new MatTableDataSource(res as Staff[]);

      // ページネーターに表示する全サイズを取得
      let data = res as any[];
      if (data.length > 0) {
        this.dataSourceLength = data[0].totalSize;
        if (data[0].distance == -1) {
          this.noPositionMsg = '当物件の位置情報が取得できませんでした。'
        }
      }
      this.loadingCompleteData = true;
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  applyFilter() {
    this.paginator.pageIndex = 0;
    this.getPicklist(this.paginator.pageIndex, this.paginator.pageSize);
  }

  /**
   * 代勤候補者の名前クリックイベント
   * @param row 
   */
  async addWorkInstead(row: any) {
    // 出発地点をセットするため、代勤者のその日の物件データを取得
    let workInsteadDataByDay = await this.getWorkInsteadData(row['staffDailyCleaning']);

    this.http.get(
      API_BASE_URL + "/api/v1/operations/renkinday?workInsteadStaffDailyCleaning=" + row.staffDailyCleaning
        + "&day=" + this.day
        , {headers: this.headers}
    ).subscribe(res => {
      let check = res as number;
      console.log(check);
      if(check > 6){
        alert("７連勤となる為、追加できません。");
      }else{

        // 選択されたスタッフのデータを取得
        this.http.get(
          API_BASE_URL + "/api/v1/operations/selectedStaff?day=" + this.day
          + "&cdStaff=" + row.staffDailyCleaning
          + "&cdSite=" + this.data['data']['cdSite']
          , {headers: this.headers}
        ).subscribe(async res => {
          let selectedStaff = res as any[];
          if (selectedStaff[0].notInstead) {
            alert(`${selectedStaff[0].notInstead}さんは出入り禁止になっているため、登録できません。`)
            return
          }
          
          // 通常清掃員の勤務予定時間から半角スペースを削除
          let time = this.data['data']['workingTime'][this.data['target'] - 1].replace(/\s/g, '');

          // GoogleMap用の出発地をセット
          let today = this.setStartEndPoint(workInsteadDataByDay, this.startTime, this.endTime, row.address1Staff, -1);
  
          // 出発地点、目的地がともにあれば、GoogleMapを表示
          let routeMapValue = this.getMap(today, this.siteAddress);

            // GoogleMapから交通費を取得
            let cb = await this.getTransportation(row['staffDailyCleaning'], routeMapValue, today);
              // 代勤データをセット
              this.setWorkInstead(
                null,
                row['staffDailyCleaning'],
                row.nameStaff,
                this.statusChoices[0]['value'],
                this.startTime,
                this.endTime,
                row.numberPhoneStaff,
                row.numberMobilePhoneStaff,
                selectedStaff[0].basicWage,
                cb,
                row.address1Staff + row.address2Staff,
                routeMapValue.start,
                '',
                this.getWorkingHours(),
                selectedStaff[0].kubunWage,
                false,
                false,
                this.noMapMsg,
                workInsteadDataByDay,
                today
              );
        });
      }
    },error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * 保存ボタンイベント
   */
  async sendWorkInstead() {
    this.saveDisabled = true;
    this.start = new Date(moment(this.start).format('YYYY-MM-DD'));
    this.end = new Date(moment(this.end).format('YYYY-MM-DD'));
    
    let data = [];
    
    // 賃金の金額をチェック
    if (this.chackWage()) {
      this.saveDisabled = false;
      return;
    }
    // 交通費の金額をチェック
    if (this.chackTrans()) {
      this.saveDisabled = false;
      return;
    }

    // // 重複時間をチェック
    // if (this.checkWorkingDuplication()) {
    //   this.saveDisabled = false;
    //   return;
    // }

    // 実働時間をチェック
    if (this.checkWorkingHours()) {
      this.saveDisabled = false;
      return;
    }

    // 契約時間との時間差分をチェック
    if (this.checkTimeDiff()){
      this.saveDisabled = false;
      return;
    }

    // デフォルト、「連日」の場合
    if (this.displaySpecifyDate == 'consecutive') {
      let startDay = moment(this.start);
      let endDay = moment(this.end);
      let days = endDay.diff(startDay, 'days');

      for (let i = 0; i <= days; i++) {
        let dateS = moment(this.start).add(i, 'days').format('YYYY-MM-DD');
        // 日付のみの配列
        this.daysSelected.push(dateS);

        // 代勤データを生成
        for (let j = 0; j < this.workInsteads.length; j++) {
          this.workInsteads[j].day = dateS;
          // アップデートを防ぐため、代勤IDをnullにセット
          // this.workInsteads[j].id = null;
          let target = {};
          target = Object.assign({},this.workInsteads[j])
          data.push(target);
        }
      }
      // this.workInsteads = data;

    // 「複数日指定」の場合
    } else if (this.displaySpecifyDate == 'multiple') {
      for (let i = 0; i < this.daysSelected.length; i++) {

        // 代勤データを生成
        for (let j = 0; j < this.workInsteads.length; j++) {
          this.workInsteads[j].day = this.daysSelected[i];
          // アップデートを防ぐため、代勤IDをnullにセット
          // this.workInsteads[j].id = null;
          let target = {};
          target = Object.assign({},this.workInsteads[j])
          data.push(target);
        }
      }
      // this.workInsteads = data;

    // 「曜日指定」の場合
    } else {
      let startDay = moment(this.start);
      let endDay = moment(this.end);
      let days = endDay.diff(startDay, 'days');

      for (let i = 0; i <= days; i++) {
        let date = moment(this.start).add(i, 'days').format('YYYY-MM-DD');
        let weekNumber = new Date(date).getDay();

        // 範囲指定の中に選択した曜日がない場合は処理をスキップ
        for (let j = 0; j < this.week.length; j++) {
          if (this.week[j] == weekNumber) {
            this.daysSelected.push(date);
          }
        }
      }

      if (this.daysSelected.length == 0) {
        alert('該当する日がありません。');
        this.saveDisabled = false;
        return;
      }

      for (let i = 0; i < this.daysSelected.length; i++) {
        // 代勤データを生成
        for (let j = 0; j < this.workInsteads.length; j++) {
          // 日付をセット
          this.workInsteads[j].day = this.daysSelected[i];
          // アップデートを防ぐため、代勤IDをnullにセット
          // this.workInsteads[j].id = null;
          let target = {};
          target = Object.assign({},this.workInsteads[j])
          data.push(target);
        }
      }

      // this.workInsteads = data;
    }

    // 入力したメモを反映
    for (let j = 0; j < data.length; j++) {
      data[j].note = this.note;
    }

    // tbl_operation_note用のデータを生成
    this.setOperationNote(this.daysSelected);

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "X-LoginAccessKey": localStorage.getItem("accessKey")
      })
    };
    
    // アップデートを防ぐため、代勤IDをnullにセット
    for (let dIdx = 0; dIdx < data.length; dIdx++) {
      let saveData = data[dIdx];
      saveData.id = null;
    }

    this.http.post(
      API_BASE_URL + "/api/v1/operations?days=" + this.daysSelected
        + "&cdSite=" + this.data['data']['cdSite']
        + "&staffDailyCleaning=" + this.data['data']['staffDailyCleaning']
        + "&sequenceNumber=" + this.data['data'].sequenceNumber
        + "&cdContract=" + this.data['data'].cdContract
        + "&nameSite=" + this.data['data'].nameSite
        + "&name=" + this.data['data'].name
        + "&show=" + this.data['data'].show
        , data
        , httpOptions
    ).subscribe(res => {
      this.sendwork(httpOptions);
      this.chkOperationNote(this.operationNote)
      // tbl_operation_noteに保存
      this.http.post(
        API_BASE_URL + '/api/v1/operationNote/save?days=' + this.daysSelected, this.operationNote, httpOptions
      ).subscribe(async res => {
        // 他の代勤の交通費を更新
        await this.updateInsteadData(data);
        this.openMailDialog(data);
        this.dialogRef.close();
      },error => {
        alert("認証に失敗しました");
        this.dialogRef.close();
        return;
      });
    },error => {
      if (error.error.status == 800 && error.error.message != ""){
        alert(error.error.message);
        this.sendwork(httpOptions);
        this.saveDisabled = false;
        if (error.error.message == '' || error.error.message.indexOf('SMS') > -1) {
          this.openMailDialog(data);
        }
      } else if (error.error.status == 801){
        alert(error.error.message);
        this.saveDisabled = false;
      }
      else{
        alert("認証に失敗しました");
        this.router.navigate(['/']);
      }
      this.dialogRef.close();
    });
  }

  /**
   * 代勤の件数表示
   * @param httpOptions 
   */
  sendwork(httpOptions){
    alert(this.workInsteads.length + "件の代勤を登録しました");
    if(this.holidayStatus != null) {
      this.holidayStatus.note = this.note;
      this.http.post(
        API_BASE_URL + "/api/v1/status?sendmail=no"
        , [this.holidayStatus]
        , httpOptions
      ).subscribe(res => {
      },error => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
      });
    }
    // this.dialogRef.close();
  }

  /**
   * 削除ボタンイベント
   * @param row 削除するデータ
   * @param i 
   */
  removeWorkInstead(row: any, i: number) {
    this.remove.push(row);
    if(window.confirm(row.workInstead + "さんの代勤登録を削除してもよろしいでしょうか？")){
      this.workInsteads.splice(i, 1);
    }
  }

  /**
   * 閉じるボタンイベント
   */
  close() {
    this.dialogRef.close();
  }
  
  /**
   * 休みの最終更新情報を取得する
   * @param data 稼働データ
   * @param target 取得するのは稼働データの何番目の情報か
   */
  private getStatusCreatedInfo(data, target) {
    let targetDay = target - 1;
    let targetStatus = data['statusArray'][targetDay] || {};
    let createdUser = targetStatus['insertedUser'];
    let createdDate = targetStatus['insertedDate'];
    return {'createdUser': createdUser, 'createdDate': createdDate};
  }

  /**
   * 出発地点をセットするため、代勤者のその日の物件データを取得
   * @param staffCd 清掃員コード（代勤者）
   * @param cb コールバック
   */
  async getWorkInsteadData(staffCd: string) {
    let data = [];
    await this.http.get(
      API_BASE_URL + "/api/v1/operations/workInsteadDataByDay?staffCd=" + staffCd
        + "&day=" + this.day
        , {headers: this.headers})
        .toPromise().then((res: any) => {
          data = res;
        })
        .catch(() => {
          alert("認証に失敗しました");
          this.router.navigate(['/']);
        });
        return data;
  }

  /**
   * 出発地点をセット
   * @param startTime 予定開始時間（画面表示している開始時間）
   * @param ad1 代勤者の住所1
   * @param operationId 代勤ID
   * @returns 
   */
  setStartEndPoint(workInsteadDataByDay, startTime: any, endTime: any, ad1: string, operationId: number){
    let data = {
      startId: null,
      startPoint: null,
      startCdSite: null,
      startSequenceNumber: null,
      endId: null,
      endPoint: null,
      endCdSite: null,
      endSequenceNumber: null,
    }
    
    if (workInsteadDataByDay) {

      // 時間比較するために「：」を削除し数値に変換
      let startT = Number(startTime.replace(':',''));

      for (let i = 0; i < workInsteadDataByDay.length; i++) {

        // 入力された開始時間より早い終了時間があれば、startPointに前物件住所をセット、編集対象は省く
        if (workInsteadDataByDay[i].endTime <= startT && workInsteadDataByDay[i].id != operationId) {
          // 終了時間は降順で取得しているため、最初にヒットしたものでOK
          data.startId = workInsteadDataByDay[i].id;
          data.startPoint = workInsteadDataByDay[i].siteAddress;
          data.startCdSite = workInsteadDataByDay[i].cdSite;
          data.startSequenceNumber = workInsteadDataByDay[i].sequenceNumber;
          break;
        }
      }
    }
    // 前物件がなければ、出発地点に代勤者の住所をセット
    if (data.startId == null) {
      data.startPoint = ad1;
    }

    // 時間比較するために「：」を削除し数値に変換
    let endT = Number(endTime.replace(':',''));

    // 直後の仕事を検索(降順になっているので、後ろから検索)
    for (let endI =  workInsteadDataByDay.length-1; endI > -1; endI--) {

      // 入力された終了時間より遅い開始時間があれば、endPointに直後の物件住所をセット、編集対象は省く
      if (workInsteadDataByDay[endI].startTime >= endT && workInsteadDataByDay[endI].id != operationId) {
        // データの最後から見ているため、最初にヒットしたものでOK
        data.endId = workInsteadDataByDay[endI].id;
        data.endPoint = workInsteadDataByDay[endI].siteAddress;
        data.endCdSite = workInsteadDataByDay[endI].cdSite;
        data.endSequenceNumber = workInsteadDataByDay[endI].sequenceNumber;
        break;
      }
    }

    // 直後の仕事がない場合
    if (data.endId == null) {
      // 次の目的地は自宅
      data.endPoint = ad1;
    }
    return data;
  }

  /**
   * 「変更を保存」ボタンの活性、非活性を設定　(開始、終了時間を確認)
   */
  setSaveDisabled() {
    if (this.workInsteads) {
      for (let i = 0; i < this.workInsteads.length; i++) {
        if (Number(this.workInsteads[i].startTime.replace(':','')) > Number(this.workInsteads[i].endTime.replace(':',''))) {
          this.saveDisabled = true;
        }
      }
    }
  }

  /**
   * 賃金を確認
   */
  chackWage() {
    let cannotSave = false;

    if (this.workInsteads) {
      for (let i = 0; i < this.workInsteads.length; i++) {
        let name = ''
        if(this.workInsteads[i].workInstead != '') {
          name = this.workInsteads[i].workInstead + 'さんの';
        }
        if (this.workInsteads[i].workInsteadKubunWage != '2' && (1500 < Number(this.workInsteads[i].basicWage))) {
          alert(name + "賃金が1,500円より高いため登録できません。");
          cannotSave = true;
          return cannotSave;
        } else if (this.workInsteads[i].workInsteadKubunWage != '2' && (1025 >= Number(this.workInsteads[i].basicWage))) {
          alert(name + "賃金が1,025円以下のため登録できません。");
          cannotSave = true;
          return cannotSave;
        }
      }
    }
    return cannotSave;
  }

  /**
   * 交通費を確認
   */
  chackTrans() {
    let cannotSave = false;

    if (this.workInsteads) {
      for (let i = 0; i < this.workInsteads.length; i++) {
        let name = ''
        if(this.workInsteads[i].workInstead != '') {
          name = this.workInsteads[i].workInstead + ' さんの';
        }
        if (4000 <= Number(this.workInsteads[i].transportationExpenses)) {
          alert(name + "交通費が4,000円以上のため登録できません。");
          cannotSave = true;
          return cannotSave;
        } else if (2000 <= Number(this.workInsteads[i].transportationExpenses)) {
          if (window.confirm(name + "交通費が2,000円以上ですがよろしいでしょうか？")) {} else {return cannotSave = true;}
        }

        // 交通費なしチェック
        if (!this.workInsteads[i].noTransportCost) {
          if (this.workInsteads[i].transportationExpenses == '' || this.workInsteads[i].transportationExpenses == 0 || this.workInsteads[i].transportationExpenses == null) {
            if (window.confirm(name + "交通費はなしでよろしいでしょうか？")) {
              this.workInsteads[i].noTransportCost = true;
              this.workInsteads[i].transportationExpenses = 0;
            } else {
              cannotSave = true;
              return cannotSave;
            }
          }
        }
      }
    }
    return cannotSave;
  }

  /**
   * 時間重複を確認
   */
  checkWorkingDuplication() {
    if (this.workInsteads != null  && this.workInsteads.length > 0) {
      for (let i = 0; i < this.workInsteads.length; i++) {
        // 引継ぎ・指導対象外
        if (this.workInsteads[i].takeOver) continue;

        let sTime = moment(this.day + ' ' + this.workInsteads[i].startTime + ':00');
        let eTime = moment(this.day + ' ' + this.workInsteads[i].endTime + ':00');

        for (let i2 = ( i + 1); i2 < this.workInsteads.length; i2++) {
            // 引継ぎ・指導対象外
            if (this.workInsteads[i2].takeOver) continue;

            let sTime2 = moment(this.day + ' ' + this.workInsteads[i2].startTime + ':00');
            let eTime2 = moment(this.day + ' ' + this.workInsteads[i2].endTime + ':00');

            if (sTime2 < eTime && eTime2 > sTime) {
              alert('重複している時間帯があります。');
              return true;
            }
        }
      }
    }
    return false;
  }

  /**
   * 実働時間を確認
   */
  checkWorkingHours() {
    let cannotSave = false;

    if (this.workInsteads != null  && this.workInsteads.length > 0) {
      for (let i = 0; i < this.workInsteads.length; i++) {
        // ステータスが「代勤OK」のみ
        if (this.workInsteads[i].status == '●：代勤OK') {
          let sTime = moment(this.day + ' ' + this.workInsteads[i].startTime + ':00');
          let eTime = moment(this.day + ' ' + this.workInsteads[i].endTime + ':00');
          let diff = eTime.diff(sTime, 'minute');
          diff = diff / 60;
          // 開始、終了時間の差よりも入力された実働時間が長い場合はアラートを表示
          if (this.workInsteads[i].workingHours > diff) {
            let name = '';
            name = this.workInsteads[i].workInstead + 'さんの';
            alert(name + '実働時間が開始~終了時間（' + diff + '時間）よりも長いです。');
            cannotSave = true;
          }
        }
      }
    }
    return cannotSave;
  }

  /**
   * 物件ごとの契約時間との差を確認
   * @returns 
   */
  checkTimeDiff() {
    let cannotSave = false;
    let status = false;
    // 既にこの日に登録している「代勤OK」の実働時間は差分にいれない
    this.timeDiff -= this.existingWorkingHours;

    if (this.workInsteads != null  && this.workInsteads.length > 0) {
      for (let i = 0; i < this.workInsteads.length; i++) {
        // ステータスが「代勤OK」のみ
        if (this.workInsteads[i].status == '●：代勤OK') {
          this.timeDiff += Number(this.workInsteads[i].workingHours);
          // 代勤OKで登録している人がいたら、フラグを立てる
          status = true;  
        }
      }
    }
    
    if (this.timeDiff > 0 && status) {
      if (!window.confirm('今月の契約時間よりも時間が多くなっています \n超過している時間は' + this.timeDiff + '時間です\n登録してもよろしいでしょうか？')) {
        cannotSave = true;
      }
    } else if (status && this.timeDiffMsg != '') {
      alert(this.timeDiffMsg);
    }

    return cannotSave;
  }

  /**
   * 賃金変更イベント
   */
  changeWage() {
    // 賃金の金額をチェック
    this.chackWage();
  }

  /**
   * 実働時間 取得
   */
  getWorkingHours() {
    // 実働時間
    let workingHours:number;

    workingHours = this.data['data']['workingHours'][this.data['target'] - 1];
    if (this.contractTime != '') {
      workingHours = this.data['data']['cdHours'][this.data['target'] - 1];
    }

    return workingHours;
  }

  /**
   * 代勤登録のデータを作成
   * @param workInsteadStaffDailyCleaning 代勤者コード
   * @param workInstead 代勤者名
   * @param status ステータス
   * @param startTime 開始時間
   * @param endTime 終了時間
   * @param numberPhone 代勤者TEL
   * @param numberMobilePhone 代勤者スマホ
   * @param basicWage 代勤者賃金
   * @param transportationExpenses 代勤者交通費
   * @param staffAddress 代勤者住所
   * @param routeMap GoogleMap
   * @param commuteTime 代勤者通勤時間
   * @param workingHours 代勤実働時間
   * @param kubunWage 代勤者賃金区分
   * @param takeOver 引継ぎ・指導
   * @param noTransportCost 交通費なし
   * @param noMapMsg GoogleMapから交通費取得時のメッセージ
   * @param today 代勤者のその日の前後の仕事
   */
  setWorkInstead(id: number, workInsteadStaffDailyCleaning: string, workInstead: string, status: string, startTime: string,
    endTime: string, numberPhone: string, numberMobilePhone: string, basicWage: string, transportationExpenses: string,
    staffAddress: string, routeMap: string, commuteTime: string, workingHours:number, kubunWage: string, takeOver: boolean,
    noTransportCost: boolean, noMapMsg: string, workInsteadDataByDay: any, today: object) {
    
    let ins = {
      id:id,
      sequenceNumber: this.data['data'].sequenceNumber,
      workInsteadStaffDailyCleaning: workInsteadStaffDailyCleaning,
      cdSite: this.data['data'].cdSite,
      staffDailyCleaning: this.data['data']['staffDailyCleaning'],
      workInstead: workInstead,
      note: this.note,
      status: status,
      startTime: startTime,
      startHour: '',
      startMinutes: '',
      endTime: endTime,
      endHour: '',
      endMinutes: '',
      numberPhone: numberPhone,
      numberMobilePhone: numberMobilePhone,
      basicWage: basicWage,
      transportationExpenses: transportationExpenses,
      workInsteadAddress: staffAddress,
      siteAddress: this.operationDialogData ? this.operationDialogData.addressSite : '',
      routeMap:routeMap,
      commuteTime: commuteTime,
      commuteHour: '',
      commuteMinutes: '',
      workingHours: workingHours,
      workInsteadKubunWage: kubunWage,
      cdContract: this.data['data'].cdContract,
      takeOver: takeOver,
      noTransportCost: noTransportCost,
      noMapMsg: noMapMsg,
      workInsteadDataByDay: workInsteadDataByDay,
      today: today
    };
    this.setTime(ins);
    this.workInsteads.push(ins);
  }

  /**
   * 交通費変更イベント
   * @param workInstead 代勤データ 
   */
  changeTrans(workInstead) {
    // 交通費に金額が入力されたら「交通費なし」チェックを外す
    if (workInstead.transportationExpenses != null && workInstead.transportationExpenses != 0 && workInstead.transportationExpenses != '') {
      workInstead.noTransportCost = false;
    }
  }

  /**
   * 交通費なしチェックイベント
   * @param workInstead 代勤データ 
   */
  changeNoTrans(workInstead) {
    if (workInstead.noTransportCost) {
      workInstead.transportationExpenses = 0;
    }
  }

  /**
   * 代勤者候補名の色をセット_緯度経度が取得不可の清掃員名は赤色
   */
  setColor(staffLatitude) {
    if (staffLatitude == -1) {
      return 'color-red';
    } else {
      return 'color-blue'
    }
  }

  /**
   * GoogleMapからスクレイピングで交通費を取得
   * @param staffCd 清掃員コード
   * @param routeMapValue GoogleMapのURL
   * @param today 代勤者のその日の前後のスケジュール
   */
  async getTransportation(staffCd, routeMapValue, today) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "X-LoginAccessKey": localStorage.getItem("accessKey")
      })
    };

    this.loadingCompleteData = false;
    this.noMapMsg = '';
    let data = {
      routeMapValue : routeMapValue,
      today : today
    }

    let result = '';

    await this.http.post(
      API_BASE_URL + "/api/v1/operations/scraping?staffCd=" + staffCd
        , data
        , httpOptions)
        .toPromise().then((res: any) => {
          result = res;
          if (res == null || res == -1) {
            this.noMapMsg = '交通費を取得できませんでした';
            result = '';
          } else {
            this.noMapMsg = '';
          }
          this.loadingCompleteData = true;
        })
        .catch(() => {
          alert("認証に失敗しました");
          this.router.navigate(['/']);
          this.loadingCompleteData = true;
        });
    return result;
  }

  /**
   * 契約時間との時間差分
   */
  public getTimeDifference() {
    let startDate = moment(this.day).format('YYYY-MM-01');
    let endDate = moment(this.day).endOf('month').format('YYYY-MM-DD');

    this.http.get(
      API_BASE_URL + '/api/v1/contractDetail/timeDifferenceWeek?s=0&e=8000&index='+ 0
        + "&size=" + 50
        + "&siteName=" + ''
        + "&startDate=" + startDate
        + "&endDate=" + endDate
        + "&cdSite=" + this.data['data'].cdSite
        + "&bySite=" + true,
      {headers: this.headers}
    ).subscribe(res => {
      let data = res as any[];
      if (data.length < 1) {
        this.timeDiffMsg = "契約時間との時間差分を取得できませんでした。"
      } else {
        this.timeDiff = data[0].totalDifference;
        console.log(data[0])
      }
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * 複数日選択ボタン　イベント
   */
  public selectDays() {
    this.multipleDay = true;
  }

  /**
   * 戻るボタン　イベント
   */
  public back() {
    this.multipleDay = false;
  }

  /**
   * 画面表示ラジオボタンイベント
   */
  initialized() {
    // 日付けを初期化
    this.start = new Date(this.data['day']);
    this.end = new Date(this.data['day']);
    // 複数日選択
    this.daysSelected = [];
    // 複数日選択（表示用）
    this.days = '';
    this.daysArray = [];
  }

  /**
   * 開始日、終了日のクラス名設定
   */
  classForTime() {
    // 連日
    if (this.displaySpecifyDate == 'consecutive') {
      return 'time-consecutive';
    // 曜日指定
    } else if (this.displaySpecifyDate == 'weekday') {
      return 'time-weekday'
    }
  }

  /**
   * 休みの開始日 変更時の処理
   * @param event 開始日
   */
  changeStart(event) {
    this.start = event.value;
  }

  /**
   * 休みの終了日 変更時の処理
   * @param event 終了日
   */
  changeEnd(event) {
    this.end = event.value;
  }

  /**
   * 「複数日指定」のカレンダーマークをクリックした際にする処理
   * @param calendar 
   */
  openCalendar(calendar: any) {
    // カレンダーを更新
    calendar.updateTodaysDate();
  }

  /**
   * 「複数日指定」選択した日を表示する
   * @param event 選択した日
   * @param calendar カレンダー
   */
  select(event: any, calendar: any) {
    let date = moment(event).format('YYYY-MM-DD');
    let dateFormat = moment(date).format('YYYY/MM/DD');
    const index = this.daysSelected.findIndex(x => x == date);
    if (index < 0) {
      this.daysSelected.push(date);
      this.daysArray.push(dateFormat);
      this.days = this.daysArray.join(", ");
    } else {
      // すでに選択済みの日をクリックした場合、配列から削除
      this.daysSelected.splice(index, 1);
      this.daysArray.splice(index, 1);
      this.days = this.daysArray.join(", ");
    }
    // 選択した日に色を付けるため、クリックするたびにカレンダーを更新
    calendar.updateTodaysDate();
  }

  /**
   * 「複数日指定」選択した日に色を付ける
   * @param event 選択した日
   * @returns
   */
  isSelected = (event: any) => {
    const date = moment(event).format('YYYY-MM-DD');
    return this.daysSelected.find(x => x == date) ? 'selected' : null;
  }

  clear() {
    this.initialized();
    this.displaySpecifyDate = 'consecutive';
  }

  /**
   * 実働時間設定
   * 開始・終了時間より計算
   * @param wInstead 代勤レコード
   */
  setWorkingHours(wInstead) {
    let sTime = moment(this.day + ' ' + wInstead.startTime + ':00');
    let eTime = moment(this.day + ' ' + wInstead.endTime + ':00');
    let diff = eTime.diff(sTime, 'minute');
    diff = diff / 60;

    if (diff <= 0) {
      wInstead.workingHours = '0.00';
    } else if (diff >= 6 ) {
      // ６時間以上の勤務で１時間休憩
      wInstead.workingHours = diff - 1
    } else {
      wInstead.workingHours = diff
    }
  }

  /**
   * tbl_operation_note用のデータを生成
   * @param days 
   */
  setOperationNote (days) {
    this.operationNote = [];
    for (let i = 0; i < days.length; i++) {
      let row = {
        // id: null,
        operationNoteId: {
          sequenceNumber: this.data['data'].sequenceNumber,
          staffDailyCleaning: this.data['data']['staffDailyCleaning'],
          cdSite: this.data['data'].cdSite,
          day: days[i],
        },
        cdContract: this.data['data'].cdContract,
        note: this.note
      }
      this.operationNote.push(row);
    }
  }

  /**
   * tbl_operation_noteからメモを取得
   */
  // getNote(cb) {
  //   this.http.get(API_BASE_URL + '/api/v1/operationNote?sequenceNumber=' + this.data['data']['sequenceNumber']
  //     + '&staffDailyCleaning=' + this.data['data']['staffDailyCleaning']
  //     + '&cdSite=' + this.data['data']['cdSite']
  //     + '&day=' + this.day,
  //     {headers: this.headers})
  //     .subscribe((res) => {
  //       if(cb){
  //         let data = res as any[];
  //         cb(data);
  //       }
  //     },
  //     error => {
  //       alert("認証に失敗しました");
  //       this.router.navigate(['/']);
  //     }
  //   );
  // }

  /**
   * googleMapのURLを生成
   * @param today 本日の代勤者の予定
   * @param siteAddress 代勤物件の住所
   * @returns 
   */
  getMap(today, siteAddress) {
    let routeMapValue = {
      start: '',
      end: ''
    };
    
    // 帰り
    if (today.endPoint != '' &&  today.endPoint != null && siteAddress != '') {
      routeMapValue.end = 'https://www.google.com/maps/dir/?api=1&origin=' + this.replaceSpace(siteAddress) + '&destination=' + this.replaceSpace(today.endPoint) + '&travelmode=transit&hl=ja&gl=jp'
    }
    // 行き
    if (today.startPoint != '' &&  today.startPoint != null && siteAddress != '') {
      routeMapValue.start = 'https://www.google.com/maps/dir/?api=1&origin=' + this.replaceSpace(today.startPoint) + '&destination=' + this.replaceSpace(siteAddress) + '&travelmode=transit&hl=ja&gl=jp'
    }

    return routeMapValue;
  }

  /**
   * スペースはハイフンに置換
   * @param value 対象値
   * @returns 
   */
  replaceSpace(value) {
    let result = value;
    result = result.replace(/ /g, '-');
    result = result.replace(/　/g, '-');
    return result;
  }

  /**
   * 他の代勤の交通費を更新
   * @param data 
   */
  async updateInsteadData(data) {
    this.updateCompleteData = false;
    
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "X-LoginAccessKey": localStorage.getItem("accessKey")
      })
    };
    
    let removeData = [];
    for (let rIdx = 0; rIdx < this.remove.length; rIdx++) {
      let re = this.remove[rIdx];
      // 一度保存されて、削除されたデータが対象
      if (re.id) {
        removeData.push(re);
      }
    }

    let allData  = data.concat(removeData);

    for (let i = 0; i < allData.length; i++) {
      let d = allData[i].today;

      let startData;
      let endData;

      // 直前に代勤が入っている場合
      if (d.startId && d.startId != 0) {
        startData = await this.getInsteadData(d.startId);
        if (startData && Object.keys(startData).length) {
          
          // 代勤者のその日の物件データを取得
          let workInsteadDataByDay = await this.getWorkInsteadData(startData.workInsteadStaffDailyCleaning);

          // 代勤者のその日のスケジュール
          let todayS = this.setStartEndPoint(workInsteadDataByDay, startData.startTime, startData.endTime, startData.workInsteadAddress, startData.id);
          // 代勤物件の住所を取得
          let siteAddressS = '';
          for (let sIdx = 0; sIdx < workInsteadDataByDay.length; sIdx++) {
            let sd = workInsteadDataByDay[sIdx];
            if (sd.id == d.startId) {
              siteAddressS = sd.siteAddress;
            }
          }
          // googleMapのURL生成
          let routeMapValue = this.getMap(todayS, siteAddressS);
          
          // GoogleMapから交通費を取得
          let cb = await this.getTransportation(startData.workInsteadStaffDailyCleaning, routeMapValue, todayS);
          if (startData.transportationExpenses != cb ||  startData.routeMap != routeMapValue.start) {
            // 代勤交通費をセット
            startData.transportationExpenses = cb;
            // googleMapのURLをセット
            startData.routeMap = routeMapValue.start;
            
            // 代勤データ更新
            await this.http.post(
              API_BASE_URL + "/api/v1/operations/update?"
                , startData
                , httpOptions)
              .toPromise().then((res: any) => {
                console.log(res);
              })
              .catch((error: any) => {
                alert("認証に失敗しました");
                this.router.navigate(['/']);
              });
          }
        }
      }
      // 直後に代勤が入っている場合
      if (d.endId && d.endId != 0) {
        endData = await this.getInsteadData(d.endId);
        if (endData && Object.keys(endData).length) {

          // 代勤者のその日の物件データを取得
          let workInsteadDataByDay = await this.getWorkInsteadData(endData.workInsteadStaffDailyCleaning);

          // 代勤者のその日のスケジュール
          let todayE = this.setStartEndPoint(workInsteadDataByDay, endData.startTime, endData.endTime, endData.workInsteadAddress, endData.id);
          // 代勤物件の住所を取得
          let siteAddressE = '';
          for (let eIdx = 0; eIdx < workInsteadDataByDay.length; eIdx++) {
            let sd = workInsteadDataByDay[eIdx];
            if (sd.id == d.endId) {
              siteAddressE = sd.siteAddress;
            }
          }
          // googleMapのURL生成
          let routeMapValue = this.getMap(todayE, siteAddressE);
          
          // GoogleMapから交通費を取得
          let cb = await this.getTransportation(endData.workInsteadStaffDailyCleaning, routeMapValue, todayE);
          if (endData.transportationExpenses != cb ||  endData.routeMap != routeMapValue.start) {
            // 代勤交通費をセット
            endData.transportationExpenses = cb;
            // googleMapのURLをセット
            endData.routeMap = routeMapValue.start;

            // 代勤データ更新
            await this.http.post(
              API_BASE_URL + "/api/v1/operations/update?"
                , endData
                , httpOptions)
              .toPromise().then((res: any) => {
                console.log(res);
              })
              .catch(() => {
                alert("認証に失敗しました");
                this.router.navigate(['/']);
              });
          }
        }
      }
    }
    this.updateCompleteData = true;
  }

  /**
   * 代勤データをIDから取得
   * @param id 
   */
  async getInsteadData(id) {
    // 既に処理済みのデータなら何もしない
    if (this.updateOperationId.length && this.updateOperationId.includes(id)) {
      return null;
    }
    this.updateOperationId.push(id);
    let data = {};
    // 選択されたスタッフのデータを取得
    await this.http.get(
      API_BASE_URL + "/api/v1/operations/findById?id=" + id
      , {headers: this.headers})
      .toPromise().then((res: any) => {
        data = res;
      })
      .catch((error: any) => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
      });
      return data;
  }

  /**
   * ステータス変更イベント
   * @param target 該当代勤データ
   */
  selectStatus(target) {
    // 代勤OKのみ
    if (target.status == '●：代勤OK') {
      // 交通費、MAPのURL取得
      target = this.getTransAndMap(target, true);
    }
  }

  /**
   * 交通費、MAPのURL取得
   * @param workInstead 
   * @param required 取得必須
   * @returns 
   */
  async getTransAndMap(workInstead, required) {
    // 代勤者のその日のスケジュール
    let today = this.setStartEndPoint(workInstead.workInsteadDataByDay, workInstead.startTime, workInstead.endTime, workInstead.workInsteadAddress, workInstead.id);
    // googleMapのURL生成
    let routeMapValue = this.getMap(today, this.siteAddress);
    workInstead.routeMap = routeMapValue.start;
    
    // データ取得必須、もしくは当日のデータの並びが変わったら交通費を再取得
    if (required || !workInstead.today || !_.isEqual(today,  workInstead.today)) {
      workInstead.today = today;
      // GoogleMapから交通費を取得
      let cb = await this.getTransportation(workInstead.workInsteadStaffDailyCleaning, routeMapValue, today);
      // 代勤交通費をセット
      workInstead.transportationExpenses = cb;
      // 交通費取得不可のコメント
      workInstead.noMapMsg = this.noMapMsg;
    }
    return workInstead;
  }

  /**
   * 交通費再取得ボタン
   */
  getTransBtn() {
    for (let index = 0; index < this.workInsteads.length; index++) {
      let w = this.workInsteads[index];
      w = this.getTransAndMap(w, true);
    }
  }
  
  /**
   * メールダイアログ表示
   */
  openMailDialog(data: any[]) {
    let target = []
    let mobile = []
    let staff = []
    for (let i = 0; i < data.length; i++) {
      const d = data[i]
      // 代勤OKの場合
      if (d.status == '●：代勤OK') {
        // 重複スタッフは排除
        if (!staff.includes(d.workInsteadStaffDailyCleaning)) {
          target.push(d)
          mobile.push({ name: d.workInstead, mobile: d.numberMobilePhone })
          staff.push(d.workInsteadStaffDailyCleaning)
        }
      }
    }

    // 対象データがある場合のみオープン
    if (target && target.length) {
      this.dialog.open(SmsDialogComponent, {
        data: {
          target,
          siteName: this.siteName,
          mobile
        }
      });
    }
  }

  chkOperationNote(onote) {
    if (onote == null || onote == undefined) {

      let raw_stacktrace = ''
      try{
        throw new Error('調査用のエラーになります。');
      }catch(e){
        raw_stacktrace = e.stack;
      }
      
      let httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          "X-LoginAccessKey": localStorage.getItem("accessKey")
        })
      };
  
      // ログ内容
      let log = {
        level: 2,
        userId: localStorage.getItem("uid"),
        serviceName: '代勤登録ダイアログ',
        operation: '登録',
        logText: 'body="' + JSON.stringify(onote) + '" \r\n' + raw_stacktrace
      }
    
      // ログ登録
      this.http.post(
        API_BASE_URL + "/api/v1/log/save", log, httpOptions
      ).subscribe(res => {
      },error => {
      });
    }
  }
}
